<template>
  <page-view>
    <section slot="content">
      ВЫ в ЛК
      {{ $store.getters.activeUser }}
      {{ $store.getters.token }}
    </section>
  </page-view>
</template>

<script>

import PageView from "@/layouts/components/PageView"

export default {
  name: 'HomePage',
  components: {
    'page-view': PageView
  },
  data() {
    return {}
  },
  mounted() {

  },
  methods: {

  }
}
</script>
